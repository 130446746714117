<template>
  <div :id="id">
    <div v-show="!show" class="input-icon input-icon-eye" @click="showHide()">
      <img src="../../assets/icons/eyeBlind.svg" alt="" />
    </div>

    <div v-show="show" class="input-icon input-icon-eye" @click="showHide()">
      <img src="../../assets/icons/eye.svg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },

  props: {
    changeIcon: {
      type: Function,
    },
    id: {
      type: String,
    },
  },
  methods: {
    showHide() {
      this.show = !this.show;
      this.changeIcon();
    },
  },
};
</script>
<style scoped lang="scss">
.passBox {
  position: relative;
  .input-icon {
    position: absolute;
    display: flex;
    top: 14px;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 0;
    height: 40px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
  }
  .input-icon-eye {
    width: 36px;
    height: 31px;
    padding: 5px;
  }
}
</style>
